<template>
  <b-container class="container-content maids-page">
    <div class="page-header">
      <h4 class="page-header-text">
        {{ $t('nav.maids') }}
        <b-img
          alt="information"
          src="../assets/images/information-icon-black.svg"
          style="margin-left: 0.1em; margin-top: -3px"
          id="maid_nationality"
        ></b-img>
        <b-tooltip target="maid_nationality" placement="bottom">{{
          $t('form_label.maid_nationality')
        }}</b-tooltip>
      </h4>
    </div>
    <FilterMaid :options="filterData" @onOptionsChange="onOptionsChange" :total="total_maids" />
    <b-row>
      <b-col
        v-if="!isPrerender && maidsAdminRecommend.length > 0"
        :xl="maidsAdminRecommend.length > 3 ? 12 : maidsAdminRecommend.length * 3"
        :lg="maidsAdminRecommend.length > 2 ? 12 : maidsAdminRecommend.length * 4"
        :md="maidsAdminRecommend.length > 1 ? 12 : maidsAdminRecommend.length * 6"
        cols="12"
        class="recommend-color text-center font-weight-bold text-dark admin-recommend"
      >
        {{ $t('form_label.admin_recommend') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="!isPrerender && maidsAdminRecommend.length > 0"
        class="recommend-color"
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maidsAdminRecommend"
        :key="`maid-recommend-${index}`"
        :class="getBorderRadiusClass(index, maidsAdminRecommend.length)"
      >
        <maid class="recommend-card" :detail="maid" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maids.filter(
          (maid) => !maidsAdminRecommend.some((m) => m.id === maid.id)
        )"
        :key="`maid-${index}`"
      >
        <maid :detail="maid" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maidsFBFriend.filter(
          (maid) => !maidsAdminRecommend.some((m) => m.id === maid.id)
        )"
        :key="maidKey('maid-friend', index)"
      >
          <maid :detail="maid" />
      </b-col>
    </b-row>
    <b-row v-if="isFetchingMaid">
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="index in 12"
        :key="maidKey('maid-skeleton', index)"
      >
        <maid :isLoading="isFetchingMaid" :detail="null" />
      </b-col>
    </b-row>
    
    <!-- <b-button variant="link" block @click="loadMoreMaids" v-if="showLoadMoreButton">{{
      $t('button.load_more')
    }}</b-button> -->
    <div v-if="showLoadMoreButton && !waiting" v-observe-visibility="visibilityChanged" class="w-100 text-center">
      <LoadingSpinner label="Loading..." style="margin-top: 10px; height: 80px;"></LoadingSpinner>
    </div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import Maid from '../components/Maid'
import FilterMaid from '../components/FilterMaid'
import LoadingSpinner from '../components/LoadingSpinner'
import { splitArrayEveryNth } from '../utils'
// import { createMeta } from '../config/seo'
import { filterMaidsMixin } from '../mixins/filterMaid'
import pick from 'lodash/pick'
import omitBy from 'lodash/omitBy'
import moment from 'moment'

const AuthStore = createNamespacedHelpers('auth')
const MaidStore = createNamespacedHelpers('maid')

@Component({
  components: { Maid, FilterMaid, LoadingSpinner },
  computed: {
    ...AuthStore.mapState(['isLoggedIn']),
    ...MaidStore.mapState(['maids', 'maidsFBFriend', 'maidsAdminRecommend', 'isFetchingMaids', 'isFetchingMaidsFriend', 'totalMaidsFriend', 'totalMaids']),
  },
  methods: {
    ...MaidStore.mapActions(['setShowMaidModal', 'getMaids', 'getMaidsFriend', 'getMaidsAdminRecommend']),
    ...MaidStore.mapMutations(['resetMaidsList']),
  },
  // metaInfo() {
  //   return createMeta({
  //     title: this.$i18n.locale === 'th' ? 'แม่บ้าน' : 'Maids',
  //     lang: this.$i18n.locale,
  //   })
  // },
  mixins: [filterMaidsMixin],
})
export default class MaidList extends Vue {
  totalMaidPages = -1
  currentMaidsPage = 0
  totalMaidFriendPage = -1
  currentMaidsFriendPage = 0
  isLastPageOfTotalMaid = false
  isLastPageOfMaidFriend = false

  waiting = false
  showMaidList = true

  isPrerender = ( window.__prerender && window.__prerender.prerendering ) || false

  // Add query params here
  params = ['limit', 'page', 'order', 'bring_equipment', 'services', 'languages', 'previosly_used']

  get total_maids() {
    return this.isLoggedIn ? this.totalMaidsFriend : this.totalMaids
  }

  get isFetchingMaid() {
    return this.isFetchingMaids || this.isFetchingMaidsFriend
  }

  maidKey(prefix, index) {
    return `${prefix}-${index}`
  }

  get showLoadMoreButton() {
    return this.isLoggedIn ? !this.isLastPageOfMaidFriend : !this.isLastPageOfTotalMaid
  }

  async mounted() {
    window.addEventListener('resize', this.handleResize)
    this.$store.commit('booking/resetState')
    this.resetMaidsList()
    await this.getListMaidsAdminRecommend()
    await this.loadMoreMaids()

    const { id } = this.$route.params
    if (id) {
      this.setShowMaidModal({ id })
      this.$root.$emit('bv::show::modal', 'maid-modal')
    }
    //window.addEventListener('scroll',this.handleScroll)
    localStorage.setItem('timeLoad', moment().unix());
    this.chackreload()
  }

  chackreload(){
    const timeLoad = new Date(localStorage.getItem('timeLoad')*1000);
    const timeReload = moment(timeLoad).add(3, 'hours')

    console.log("timeLoad",moment(timeLoad).format());
    console.log("timeReload",timeReload.format());
    console.log("time",moment().format());
    if(timeReload.isBefore(moment()) || timeReload.isSame(moment()) ){
      location.reload()
    }else{
      setTimeout(this.chackreload,1000*60*5)
    }
  }

  async loadMoreMaids() {
    if(this.waiting) return
    this.waiting = true
    if (this.isLoggedIn) {
      await this.getMaidListWithFriends()
    } else {
      await this.getMaidList()
    }
    this.waiting = false
  }

  visibilityChanged(isVisible) {
    if (!isVisible) {
      return
    }
    this.loadMoreMaids()
  }

  @Watch('isLoggedIn')
  async onLoginStateChanged() {
    await this.onOptionsChange(this.filterData)
  }

  async getMaidList() {
    this.$root.$emit('bv::dropdown::hide', 'dropdown')
    let options = { ...this.getMaidListOptions(), page: this.currentMaidsPage + 1 } // Get options using filterMaid mixin
    options = pick(options, this.params)
    options = omitBy(options, (val) => !val)
    const { page_count } = await this.getMaids({ options })
    this.totalMaidPages = page_count
    this.currentMaidsPage += 1
    this.isLastPageOfTotalMaid = this.currentMaidsPage === page_count || page_count === 0
  }

  async getMaidListWithFriends() {
    if (this.isLoggedIn) {
      let options = {
        ...this.getMaidListWithFriendsOptions(),
        page: this.currentMaidsFriendPage + 1,
      }
      options = pick(options, this.params)
      options = omitBy(options, (val) => !val)
      console.log('component: options', options)
      const { page_count } = await this.getMaidsFriend({ options })
      this.totalMaidFriendPage = page_count
      this.currentMaidsFriendPage += 1
      this.isLastPageOfMaidFriend = this.currentMaidsFriendPage === page_count || page_count === 0
    }
  }

  async getListMaidsAdminRecommend() {
    let options

    if (this.isLoggedIn) {
      options = {
        ...this.getMaidListWithFriendsOptions(),
        page: 1,
      }
    } else {
      options = { 
        ...this.getMaidListOptions(), 
        page: 1 
      }
    }

    options = pick(options, this.params)
    options = omitBy(options, (val) => !val)
    await this.getMaidsAdminRecommend({ options, isLoggedIn: this.isLoggedIn })
  }
  
  async onOptionsChange(options) {
    this.totalMaidPages = -1
    this.currentMaidsPage = 0
    this.totalMaidFriendPage = -1
    this.currentMaidsFriendPage = 0
    this.filterData = options
    this.resetMaidsList()
    await this.getListMaidsAdminRecommend()
    await this.loadMoreMaids()
  }

  get maidList() {
    const maids = splitArrayEveryNth(this.maids, 4)
    const maidsFriend = splitArrayEveryNth(this.maidsFBFriend, 4)

    const totalMaids = maids.length
    const totalMaidsFriend = maidsFriend.length

    const totalRepeat = totalMaids <= totalMaidsFriend ? totalMaids : totalMaidsFriend

    let maidList = []
    for (let i = 0; i < totalRepeat; i++) {
      maidList = [...maidList, ...maids[i], ...maidsFriend[i]]
    }

    return maidList
  }
  // Auto scroll
  // handleScroll() {
  //   if(window.scrollY + window.innerHeight >= document.body.scrollHeight - 50){
  //     this.loadMoreMaids = [...this.maidList, ...this.loadMoreMaids()]
  //   }
  // }
  beforeDestroy() {
    this.resetMaidsList()
    window.removeEventListener('resize', this.handleResize)
  }

  getBorderRadiusClass(index, length) {
    if (!this.showMaidList) {
      return ''
    }
    const mediaSize = window.innerWidth
    if (mediaSize < 768) {
      return index === length - 1 ? 'rounded-recommend-bottom' : ''
    }

    if (mediaSize < 992) {
      const lastRowHasOneItem = length % 2 === 1
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }

    if (mediaSize < 1200) {
      const lastRowHasOneItem = length % 3 === 1
      const lastRowHasTwoItem = length % 3 === 2
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasTwoItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
        if(index === length - 3){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 3){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }

    if (mediaSize >= 1200) {
      const lastRowHasOneItem = length % 4 === 1
      const lastRowHasTwoItem = length % 4 === 2
      const lastRowHasThreeItem = length % 4 === 3
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasTwoItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
        if(index === length - 3){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasThreeItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 3){
          return 'rounded-recommend-left'
        }
        if(index === length - 4){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 4){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }
  }

  resizeTimeout = null
  handleResize() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        this.showMaidList = false
        setTimeout(() => {
          this.showMaidList = true
        }, 0)
      }, 100)
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-recommend {
  font-size: 1.2em;
  border-radius: 6px 6px 0 0;
  padding-top: 1em;
  padding-bottom: calc(1em - 10px);
}

.recommend-color {
  background-color: rgba(255, 194, 207, 0.373);
}

.recommend-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rounded-recommend-right {
  border-radius : 0 0 6px 0;
}

.rounded-recommend-left {
  border-radius: 0 0 0 6px;
}

.rounded-recommend-bottom {
  border-radius: 0 0 6px 6px;
}

@media (max-width: 767px) {
  .admin-recommend {
    font-size: 1em;
  }
}
</style>